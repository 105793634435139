<script setup lang="ts">
interface Card {
  imageAlt: string
  imageSrc: string
  imageSrcSets: Array<{
    width: number
    src: string
  }>
  title: string
  to: string
}

interface RawImage {
  altText: string
  cardDestinationUrl: string
  cardText: string
  image: {
    url: string
  }
}

const props = defineProps({
  heading: {
    type: String,
    default: null,
  },
  ctaText: {
    type: String,
    default: null,
  },
  ctaDestinationUrl: {
    type: String,
    default: null,
  },
  images: {
    type: Array as PropType<Array<RawImage>>,
    required: true,
  },
})

const getImageCards = (images: Array<RawImage>): Array<Card> => {
  return images.map((image) => {
    return {
      title: image.cardText,
      to: image.cardDestinationUrl,
      imageSrc: image.image.url,
      imageAlt: image.altText,
      imageSrcSets: [950, 475].map((width) => ({
        width,
        src: transformImage(
          image.image.url,
          `f_auto,fl_progressive,q_auto:low,w_${width},g_auto:subject,c_fill,ar_4:3`
        ),
      })),
    }
  })
}

const imageCards = getImageCards(props.images)
</script>

<template>
  <section class="ImageCardGrid">
    <h2 v-if="heading" class="ImageCardGrid_Heading" data-test-id="heading">
      {{ heading }}
    </h2>
    <div class="ImageCardGrid_Grid">
      <SkyImageCard
        v-for="(imageCard, index) in imageCards"
        :key="index"
        class="ImageCardGrid_Card"
        tag="a"
        :image-alt="imageCard.imageAlt"
        :image-src="imageCard.imageSrc"
        :image-src-sets="imageCard.imageSrcSets"
        :title="imageCard.title"
        :to="imageCard.to"
      />
    </div>
    <div v-if="ctaText && ctaDestinationUrl" class="ImageCardGrid_CTA">
      <SkyLink
        inline
        tag="a"
        :to="ctaDestinationUrl"
        class="ImageCardGrid_CTA_Text"
        data-test-id="ctaLink"
      >
        {{ ctaText }}
      </SkyLink>
    </div>
  </section>
</template>

<style lang="scss">
@import '@/assets/style/widget';

.ImageCardGrid {
  &_Heading {
    margin-top: 0;
    margin-bottom: calc(var(--spacing-3x) + 1px);
    @include type-brand-display('2');

    @include for-medium-up {
      margin-bottom: calc(var(--spacing-6x) + 2px);
    }
  }

  &_Grid {
    display: grid;
    gap: var(--spacing-2x);
    grid-template-columns: repeat(2, 1fr);

    @include for-medium-up {
      gap: var(--spacing-4x);
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &_Card {
    width: 100%;
  }

  &_CTA {
    text-align: center;
    margin-top: var(--spacing-4x);
    @include for-medium-up {
      margin-top: var(--spacing-8x);
    }

    &_Text {
      font-size: var(--font-size-75);
      @include for-medium-up {
        font-size: var(--font-size-100);
      }
    }
  }
}
</style>
