<script setup lang="ts">
import {
  FALLBACK_PRODUCT_IMAGE,
  PRODUCT_TILE_TRANSFORMATIONS,
} from '~/constants/images'
import { BACK_SOON_BADGE_TEXT } from '~/constants/productBadges'
import type { CatalogItem } from '~/types/catalog'

/**
 * The component for ShoppingMedium widgets.
 *
 * Displays the specified catalog items at varying screen sizes, with an optional
 * headline.
 */
const props = defineProps({
  /**
   * The name of the CMS widget.
   */
  name: {
    type: String,
    default: '',
  },
  /**
   * The headline to display for the widget, if `isHeadlineEnabled` truthy.
   */
  headline: {
    type: String,
    default: '',
  },
  /**
   * Whether or not to display a headline for the widget.
   */
  isHeadlineEnabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Ids of the variants to display as catalog items; max length of 6 items.
   */
  variantIds: {
    type: Array as PropType<Array<string>>,
    required: true,
    validator: (prop: Array<string>) => prop.length > 0 && prop.length < 7,
  },
})

const { $events } = useNuxtApp()
const { results, resultId, fetchRecommendations } = useCatalogRecommendations()
const { isSmallUp } = useSkyBreakpoint()

const trackCatalogClick = (item: CatalogItem) => {
  $events.emit(
    ...$events.catalogClick({
      product: {
        name: item.productName,
        brand: item.brandName,
      },
      contentSource: 'shopping widget tile',
      sourceName: props.headline,
      sourceCMSName: props.name,
    })
  )
}

watch(
  () => props.variantIds,
  (variantIds) => {
    if (variantIds.length) {
      fetchRecommendations('browse', { variantIds })
    }
  },
  { immediate: true }
)
</script>

<template>
  <section class="WidgetShoppingMedium">
    <h2 v-if="isHeadlineEnabled" class="WidgetShoppingMedium_Headline">
      {{ headline }}
    </h2>
    <ul
      class="WidgetShoppingMedium_ItemGrid"
      data-cnstrc-browse
      :data-cnstrc-num-results="results.length"
      :data-cnstrc-result-id="resultId"
    >
      <li
        v-for="item in results"
        :key="item.variantId"
        class="WidgetShoppingMedium_Item"
        :data-cnstrc-item-id="item.id"
        :data-cnstrc-item-name="item.name"
        :data-cnstrc-item-price="item.price"
        :data-cnstrc-item-variation-id="item.variantId"
      >
        <SkyCard rounded>
          <SkyProductTile
            :class="{
              'WidgetShoppingMedium_ProductTile--vip':
                item.hasVipSpecialPricingDiscount,
            }"
            :badge-inverted="item.badgeText === BACK_SOON_BADGE_TEXT"
            :badge-text="item.badgeText"
            :brand-name="item.brandName"
            hide-zero-values
            :image-alt="item.name"
            :image-placeholder="FALLBACK_PRODUCT_IMAGE"
            :image-src="
              transformImage(
                item.imageSrc,
                `${PRODUCT_TILE_TRANSFORMATIONS}${
                  item.outOfStock ? ',e_grayscale' : ''
                }`
              )
            "
            :offer-price="item.price"
            :offer-text="
              item.hasVipSpecialPricingDiscount
                ? `VIP ${item.discountText}`
                : item.discountText
            "
            :price="item.listPrice"
            :product-name="item.productName"
            :product-url="`${item.productUrl}`"
            :product-rating="item.reviewRating"
            :rating-count="item.reviewCount"
            :secondary-text="item.secondaryText"
            :variant-count="item.variants.length || undefined"
            :wide-layout="!isSmallUp"
            align-all-items
            show-divider
            @click="trackCatalogClick(item)"
          >
            <template
              v-if="item.badgeText || item.hasVipSpecialPricingDiscount"
              #topBadge
            >
              <SkyBadge
                v-if="item.badgeText"
                :inverted="item.badgeText === BACK_SOON_BADGE_TEXT"
              >
                {{ item.badgeText }}
              </SkyBadge>
              <SkyBadge
                v-if="item.hasVipSpecialPricingDiscount"
                class="WidgetShoppingMedium_ProductTileBadge--vip"
              >
                VIP Discount
              </SkyBadge>
            </template>
            <template v-if="item.variantId" #cta>
              <SkyButton v-if="item.outOfStock" :disabled="true" dense block>
                Out of Stock
              </SkyButton>
              <SkyLink
                v-else
                :to="`/statc/?v=${item.variantId}`"
                class="WidgetShoppingMedium_AddToCart"
                button
                dense
              >
                Add to cart
              </SkyLink>
            </template>

            <template v-if="item.reviewRating === null && item.sku" #rating>
              <div
                :data-bv-product-id="item.sku"
                :data-bv-redirect-url="`${item.productUrl}#reviews-section`"
                data-bv-seo="false"
                data-bv-show="inline_rating"
                data-test-id="bv-inline-star-rating"
              />
            </template>
          </SkyProductTile>
        </SkyCard>
      </li>
    </ul>
  </section>
</template>

<style lang="scss">
.WidgetShoppingMedium {
  display: grid;
  padding: var(--spacing-10x) 0;

  &_Headline {
    text-align: center;
    margin-bottom: var(--spacing-10x);
    @include type-brand-heading('2');

    @include for-medium-up {
      @include type-brand-display('2');
      margin-bottom: var(--spacing-12x);
    }
  }

  &_ItemGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: var(--spacing-8x);
    line-height: var(--line-height-default);
    padding: 0;
    list-style: none;

    @include for-medium-up {
      grid-template-columns: repeat(auto-fit, 200px);
      justify-content: center;
    }

    @include for-large-up {
      gap: var(--spacing-12x);
    }
  }

  &_Item {
    justify-self: center;

    .SkyCard {
      height: 100%;
    }
  }

  &_AddToCart {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }

  &_ProductTileBadge {
    &--vip {
      --surface-color-dark: var(--surface-color-vip);
    }
  }

  &_ProductTile {
    &--vip {
      .SkyPrice {
        --text-color-savings: var(--text-color-vip);
      }
    }
  }
}
</style>
