<script setup lang="ts">
/**
 * The Banner Small Headline component.
 *
 * A very simple presentational component that displays
 * a full width banner and headline text.
 */

const props = defineProps({
  background: {
    type: String as PropType<'Pattern' | 'Cream' | 'Frost'>,
    default: '',
  },
  headline: {
    type: String,
    required: true,
  },
})

const componentClasses = computed<string>(() => {
  const baseClass = 'BannerSmallHeadline'
  const availableBackground: Record<string, string> = {
    pattern: `${baseClass}--pattern`,
    cream: `${baseClass}--cream`,
    frost: `${baseClass}--frost`,
  }

  return availableBackground[props.background?.toLowerCase()] || ''
})
</script>

<template>
  <div class="BannerSmallHeadline" :class="componentClasses">
    <h2 class="BannerSmallHeadline_Heading">
      {{ headline }}
    </h2>
  </div>
</template>

<style lang="scss">
.BannerSmallHeadline {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 80px var(--spacing-4x);

  @include for-small-down {
    padding: var(--spacing-10x) var(--spacing-4x);
  }

  &_Heading {
    @include type-heading('1');

    @include for-small-down {
      font-size: var(--font-size-300);
    }
  }

  &--pattern {
    background-image: var(--background-pattern-url);
    background-position: 50%;
    background-size: cover;
  }

  &--cream {
    background-color: var(--surface-color-cream);
  }

  &--frost {
    background-color: var(--surface-color-frost);
  }
}
</style>
