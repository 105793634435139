<script setup lang="ts">
import { SkyCarousel } from '@groveco/skylight'
import type { ContentfulAsset } from '~/types/contentful'

interface Quote {
  alt: string
  img: string
  source: string
  text: string
}

const MAX_QUOTES = 3

const props = defineProps({
  hasStars: {
    type: Boolean,
    default: false,
  },
  quoteText1: {
    type: String,
    default: '',
  },
  quoteSourceImage1: {
    type: Object as PropType<ContentfulAsset>,
    default: null,
  },
  quoteSourceImageAlt1: {
    type: String,
    default: '',
  },
  quoteSource1: {
    type: String,
    default: '',
  },
  quoteText2: {
    type: String,
    default: '',
  },
  quoteSourceImage2: {
    type: Object as PropType<ContentfulAsset>,
    default: null,
  },
  quoteSourceImageAlt2: {
    type: String,
    default: '',
  },
  quoteSource2: {
    type: String,
    default: '',
  },
  quoteText3: {
    type: String,
    default: '',
  },
  quoteSourceImage3: {
    type: Object as PropType<ContentfulAsset>,
    default: null,
  },
  quoteSourceImageAlt3: {
    type: String,
    default: '',
  },
  quoteSource3: {
    type: String,
    default: '',
  },
})

const quotes = computed<Quote[]>(() =>
  Array.from({ length: MAX_QUOTES }, (_, arrayIndex) => {
    const index = arrayIndex + 1
    const image = props[
      `quoteSourceImage${index}` as keyof typeof props
    ] as ContentfulAsset | null
    return {
      alt:
        (props[
          `quoteSourceImageAlt${index}` as keyof typeof props
        ] as string) ||
        image?.description ||
        '',
      img: image?.url || '',
      source: props[`quoteSource${index}` as keyof typeof props] as string,
      text: props[`quoteText${index}` as keyof typeof props] as string,
    }
  }).filter(({ text }) => text)
)

const containerType = computed(() =>
  quotes.value.length > 1 ? SkyCarousel : 'div'
)
</script>

<template>
  <component :is="containerType" class="CarouselQuote" pagination>
    <blockquote
      v-for="(quote, quoteIndex) in quotes"
      :key="`CarouselQuote_Quote-${quoteIndex}`"
      class="CarouselQuote_Quote"
    >
      <SkyRating
        v-if="hasStars"
        class="CarouselQuote_Rating"
        size="20px"
        :rating="5"
      />
      <h2 class="CarouselQuote_Copy">
        {{ quote.text }}
      </h2>
      <cite v-if="quote.source" class="CarouselQuote_Source">
        {{ quote.source }}
      </cite>
      <SkyImage
        v-if="quote.img"
        class="CarouselQuote_Image"
        :alt="quote.alt"
        :src="transformImage(quote.img, 'f_auto,fl_progressive,q_auto,w_200')"
      />
    </blockquote>
  </component>
</template>

<style lang="scss">
.CarouselQuote {
  background-color: var(--surface-color-frost);
  padding: var(--spacing-10x) 0;

  &_Quote {
    text-align: center;
    padding: var(--spacing-6x) var(--spacing-8x);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    color: var(--text-color-primary);
    --accent-color: var(--text-color-primary);
  }

  &_Rating {
    margin-top: var(--spacing-8x);
  }

  &_Copy {
    margin: var(--spacing-8x) 0;
    @include type-brand-display('2');
  }

  &_Source {
    display: block;
    text-transform: uppercase;
    font-size: var(--font-size-75);
    font-style: normal;
    letter-spacing: var(--letter-spacing-loose);
    margin-bottom: var(--spacing-8x);
  }

  &_Image {
    max-width: 128px;

    @include for-medium-up {
      max-width: 160px;
    }
  }
}
</style>
