<script setup lang="ts">
import { SkyImage } from '@groveco/skylight'
import type { ContentfulAsset } from '~/types/contentful'

const baseTransforms =
  'f_auto,fl_progressive,q_auto:low,g_auto:subject,ar_4:3,c_fill'

defineProps({
  headline: {
    type: String,
    default: '',
  },
  images: {
    type: Array as PropType<ContentfulAsset>,
    default: () => [],
  },
})

const getImageSrcSets: Object<any, any> = (image: ContentfulAsset) => {
  const imagePath = image?.url || ''
  const srcsets = imagePath
    ? [620, 1240].map((width) => ({
        width,
        src: transformImage(imagePath, `${baseTransforms},w_${width}`),
      }))
    : []

  return {
    src: srcsets[0].src,
    alt: image?.description || '',
    srcsets,
  }
}
</script>

<template>
  <div class="GridImage">
    <h2 class="GridImage_Heading" data-test-id="headline">
      {{ headline }}
    </h2>
    <SkyImage
      v-for="(image, index) in images.slice(0, 5)"
      :key="index"
      :class="{ GridImage_Featured: !index }"
      v-bind="getImageSrcSets(image)"
    />
  </div>
</template>

<style lang="scss">
.GridImage {
  margin: 0 auto;
  display: grid;
  grid-gap: var(--spacing-4x);
  grid-template-columns: repeat(2, 1fr);
  padding: var(--spacing-10x) 0;

  @include for-medium-up {
    grid-template-columns: repeat(4, 1fr);
    padding: 80px 0;
  }

  &_Heading {
    @include type-brand-display('2');
    text-align: center;
    grid-column: 1 / -1;
  }

  &_Featured {
    grid-row: span 2;
    grid-column: span 2;
  }
}
</style>
