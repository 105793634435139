<script setup lang="ts">
import type { MarkdownParsedContent } from '@nuxt/content/dist/runtime/types'
import type { ContentfulAsset } from '~/types/contentful.ts'

/**
 * The Product Launch Hero widget.
 *
 * Our product launch hero widget that is hydrated with data pulled
 * from our cms. This component is used on a variety of acquisition
 * and editorial pages.
 *
 * This component has a lot of responsibility that requires us to make
 * some template and design decisions.
 * 1. In mobile:
 *  - The details media image should be visible.
 *  - The hero background should be replaced with the `--linen` color.
 * 2. In desktop:
 *  - The details media should be hidden.
 *  - The hero background should use the dynamic background image.
 */
type SkyImageInstance = InstanceType<typeof SkyImage>
type SrcSets = SkyImageInstance['srcsets']

defineOptions({
  inheritAttrs: false,
})

const markdownComponents = {
  a: 'ContentProseA',
}

const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  headline: {
    type: String,
    required: true,
  },
  isImageBackground: {
    type: Boolean,
    default: false,
  },
  heroBackground: {
    type: String as PropType<'Hero Image' | 'Pattern' | 'Color1' | 'Color2'>,
    default: '',
  },
  isPanelLeft: {
    type: Boolean,
    default: false,
  },
  imageHeroDesktop: {
    type: Object as PropType<ContentfulAsset>,
    default: null,
  },
  imageHeroMobile: {
    type: Object as PropType<ContentfulAsset>,
    default: null,
  },
  heroImageAltText: {
    type: String,
    default: '',
  },
  isHeadlineLogoEnabled: {
    type: Boolean,
    default: false,
  },
  headlineLogo: {
    type: Object as PropType<ContentfulAsset>,
    default: null,
  },
  headlineLogoAltText: {
    type: String,
    default: '',
  },
  copy: {
    type: Object as PropType<MarkdownParsedContent>,
    default: () => ({ value: null }),
  },
  isButtonEnabled: {
    type: Boolean,
    default: false,
  },
  buttonLabel: {
    type: String,
    default: '',
  },
  buttonLink: {
    type: String,
    default: '',
  },
})

const { isMediumUp } = useSkyBreakpoint()

const heroBackgroundEnabled = computed<boolean>(() => {
  return props.heroBackground === 'Hero Image'
})

const heroBackgroundSrc = computed<string>(() => {
  const imageUrl = props.imageHeroDesktop?.url
  return imageUrl ? transformImage(imageUrl, 'f_auto') : ''
})

const sectionClasses = computed<string>(() => {
  const baseClass = 'ProductLaunchHero'
  if (heroBackgroundEnabled.value) return `${baseClass}--hero`

  const availableBackground: Record<string, string> = {
    color1: `${baseClass}--linen`,
    color2: `${baseClass}--frost`,
    pattern: `${baseClass}--pattern`,
  }

  return (
    availableBackground?.[
      props.heroBackground && props.heroBackground.toLowerCase()
    ] || availableBackground.pattern
  )
})

const sectionStyles = computed<string | undefined>(() => {
  return heroBackgroundEnabled.value && isMediumUp.value
    ? `background-image: url('${heroBackgroundSrc.value}')`
    : undefined
})

const detailsClasses = computed<Record<string, boolean>>(() => {
  const baseClass = 'ProductLaunchHero_Details'
  return {
    [`${baseClass}--image-on-right`]: props.isPanelLeft,
    [`${baseClass}--offset-panel`]: hideMedia.value && !props.isPanelLeft,
  }
})

const hideMedia = computed<boolean>(() => {
  return heroBackgroundEnabled.value && isMediumUp.value
})

const logoSrc = computed<string>(() => {
  const logoUrl = props.headlineLogo?.url
  return logoUrl ? transformImage(logoUrl, 'f_auto,q_auto,w_130') : ''
})

const imageSrc = computed<string>(() => {
  const imageUrl = props.imageHeroMobile?.url
  return imageUrl
    ? transformImage(imageUrl, 'f_auto,q_auto:low,fl_progressive,w_620')
    : ''
})

const imageAlt = computed<string>(() => {
  return props.heroImageAltText || props.imageHeroMobile?.description
})

const imageSrcSets = computed<SrcSets>(() => {
  const imageUrl = props.imageHeroMobile?.url
  if (!imageUrl) {
    return []
  }

  const transforms: Array<{ res: string; width: number }> = [
    { res: '1x', width: 620 },
    { res: '2x', width: 1240 },
  ]

  return transforms.map(
    (transform: {
      res: string
      width: number
    }): { resolution: string; src: string } => ({
      resolution: transform.res,
      src: transformImage(
        imageUrl,
        `f_auto,q_auto:low,fl_progressive,w_${transform.width}`
      ),
    })
  )
})
</script>

<template>
  <section
    :id="id"
    class="ProductLaunchHero"
    :class="sectionClasses"
    :style="sectionStyles"
  >
    <SkyImage
      v-if="isHeadlineLogoEnabled"
      class="ProductLaunchHero_FloatingLogo"
      :src="logoSrc"
      :alt="headlineLogoAltText"
      width="100%"
    />
    <div class="ProductLaunchHero_Content">
      <div v-if="!hideMedia" class="ProductLaunchHero_Media">
        <SkyImage
          :src="imageSrc"
          :alt="imageAlt"
          :srcsets="imageSrcSets"
          data-test-id="media_image"
        />
      </div>
      <SkyCard
        class="ProductLaunchHero_Details"
        :class="detailsClasses"
        rounded
      >
        <SkyImage
          v-if="isHeadlineLogoEnabled"
          class="ProductLaunchHero_DetailsLogo"
          :src="logoSrc"
          :alt="headlineLogoAltText"
          width="100%"
        />
        <h1
          v-if="headline"
          class="ProductLaunchHero_Headline"
          data-test-id="headline"
        >
          {{ headline }}
        </h1>
        <ContentRendererMarkdown
          class="ProductLaunchHero_Copy"
          :value="copy"
          :components="markdownComponents"
        />
        <div class="ProductLaunchHero_CTAContainer">
          <SkyLink
            v-if="isButtonEnabled"
            class="ProductLaunchHero_CTA"
            :to="buttonLink"
            button
            block
          >
            {{ buttonLabel }}
          </SkyLink>
        </div>
      </SkyCard>
    </div>
  </section>
</template>

<style lang="scss">
@import '@/assets/style/widget';
$background-pattern-url: '//images.grove.co/upload/v1556056115/global/Line%20Illustrations/bgd-hero-leaf.svg';

.ProductLaunchHero {
  display: flex;
  justify-content: center;
  position: relative;

  @include for-medium-up {
    padding: var(--spacing-10x) var(--spacing-4x);
  }

  &--pattern {
    background: url($background-pattern-url);
  }

  &--hero {
    @include for-small-down {
      background-color: var(--surface-color-linen);
      background-image: none;
    }

    @include for-medium-up {
      background-size: cover;
      background-position: top;
    }
  }

  &--linen {
    background: var(--surface-color-linen);
  }

  &--frost {
    background: var(--surface-color-frost);
  }

  &_Content {
    margin: 0;
    display: grid;
    max-width: 62.5rem;

    @include for-medium-up {
      grid-template-columns: repeat(2, 1fr);
      gap: var(--spacing-8x);
    }
  }

  &_Media {
    display: flex;
    justify-content: center;

    @include for-medium-up {
      align-items: flex-start;
    }
  }

  &_Details {
    transform: translateY(calc(#{var(--spacing-6x)} * -1));
    margin-right: var(--spacing-2x);
    margin-left: var(--spacing-2x);
    padding: var(--spacing-6x);
    z-index: var(--z-index-default);
    width: auto;

    @include for-medium-up {
      transform: none;
      padding: 0;
      margin: 0;
      background: none;
      border-radius: none;
      border: none;
      z-index: inherit;
    }

    &--image-on-right {
      @include for-medium-up {
        order: -1;
      }
    }

    &--offset-panel {
      @include for-medium-up {
        grid-column: 2;
      }
    }
  }

  &_Headline {
    @include type-brand-heading('1');
    margin-top: var(--spacing-4x);
    margin-bottom: var(--spacing-4x);

    @include for-medium-up {
      font-size: var(--font-size-700);
    }
  }

  &_Copy {
    // Disabling stylelint here as these legacy utility classes violate our CSS naming
    // conventions and we cannot _easily_ rename them as we have allowed content creators
    // to make use of them in raw HTML templates within our CMS.
    /* stylelint-disable selector-class-pattern */
    .section-heading {
      @include type-section('m');
      color: var(--text-color-secondary);
      margin-bottom: var(--spacing-2x);
    }
    /* stylelint-enable selector-class-pattern */

    font-size: var(--font-size-200);
    line-height: var(--line-height-default);

    @include for-medium-up {
      line-height: var(--line-height-default);
    }
  }

  &_CTAContainer {
    width: 100%;
    min-width: 300px;

    @include for-medium-up {
      width: fit-content;
    }
  }

  &_CTA {
    text-align: center;
    margin-top: var(--spacing-4x);

    @include for-medium-up {
      display: flex;
    }
  }

  &_FloatingLogo {
    // Raise logo above background
    z-index: var(--z-index-float);
    position: absolute;
    left: 0;
    margin: var(--spacing-4x);
    width: 80px;

    @include for-medium-up {
      display: none;
    }
  }

  &_DetailsLogo {
    display: none;

    @include for-medium-up {
      width: 128px;
      display: inline-block;
    }
  }
}
</style>
