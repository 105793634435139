<template>
  <section class="ValueProps">
    <div
      class="ValueProps_Wrapper"
      :class="{
        'ValueProps_Wrapper-Theme-dark': colorMode === 'dark',
        'ValueProps_Wrapper-Theme-transparent': colorMode === 'transparent',
        'ValueProps_Wrapper-Theme-VIP': colorMode === 'VIP',
      }"
      data-test-id="wrapper"
    >
      <div class="ValueProps_Container">
        <SkyImage
          v-if="transformedHeaderImage"
          class="ValueProps_HeaderImage"
          data-test-id="header-image"
          :src="transformedHeaderImage"
          :alt="headerImageAltText"
          :style="headerImageStyles"
        />
        <h2 v-if="headline" class="ValueProps_Header" data-test-id="header">
          {{ headline }}
        </h2>
        <div v-if="subhead" class="ValueProps_Subhead" data-test-id="subhead">
          {{ subhead }}
        </div>
        <ul class="ValueProps_List">
          <li
            v-for="prop in basicValueProps"
            :key="prop.title"
            data-test-id="basic-value-prop"
            class="ValueProps_List_Item"
          >
            <div
              class="ValueProps_List_Item_Content"
              :class="{
                ValueProps_List_Item_Content_WithImage: prop.image,
              }"
            >
              <SkyImage
                v-if="prop.image"
                class="ValueProps_List_Item_Image"
                data-test-id="value-prop-image"
                :src="transformImage(prop.image.url || '', valuePropTransform)"
                :alt="prop.imageAltText"
              />
              <SkyIcon
                v-else
                name="checkmark"
                data-test-id="value-prop-icon"
                :size="checkmarkIconSize"
              />
              <div>
                <div>{{ prop.title }}</div>
                <div
                  v-if="prop.subhead"
                  class="ValueProps_List_Item_Content_Subhead"
                >
                  {{ prop.subhead }}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="ctaText" class="ValueProps_Cta">
        <SkyLink
          data-test-id="value-prop-cta"
          :to="ctaDestinationUrl"
          :inline="!isCtaButton"
          :button="isCtaButton"
          :block="isCtaButton && !isSmallUp"
        >
          {{ ctaText }}
        </SkyLink>
      </div>
    </div>
    <p
      v-if="footerDisclaimer"
      class="ValueProps_Disclaimer"
      data-test-id="disclaimer-text"
    >
      {{ footerDisclaimer }}
    </p>
  </section>
</template>
<script setup lang="ts">
import type { ContentfulAsset } from '~/types/contentful'
import type { ValueProp } from '~/types/valueProp'

const transformValue = 'f_auto,fl_progressive,w_510,q_auto'

const props = defineProps({
  colorMode: {
    type: String,
    default: 'dark',
  },
  headline: {
    type: String,
    default: '',
  },
  subhead: {
    type: String,
    default: '',
  },
  basicValueProps: {
    type: Array<ValueProp>,
    required: true,
  },
  headerImage: {
    type: Object as PropType<ContentfulAsset>,
    default: null,
  },
  mobileHeaderImageWidth: {
    type: String,
    default: null,
  },
  headerImageAlt: {
    type: String,
    default: '',
  },
  headerImageDesktop: {
    type: Object as PropType<ContentfulAsset>,
    default: null,
  },
  headerImageDesktopAlt: {
    type: String,
    default: '',
  },
  ctaText: {
    type: String,
    default: '',
  },
  ctaDestinationUrl: {
    type: String,
    default: '',
  },
  isCtaButton: {
    type: Boolean,
    default: false,
  },
  footerDisclaimer: {
    type: String,
    default: '',
  },
})

const { isMediumUp, isSmallUp, isReady } = useSkyBreakpoint()
const valuePropTransform = 'f_auto,fl_progressive,w_128,q_auto'

const transformedHeaderImage = computed(() => {
  if (!isReady.value) return ''
  if (isMediumUp.value && props.headerImageDesktop)
    return transformImage(props.headerImageDesktop?.url || '', transformValue)
  return transformImage(props.headerImage?.url || '', transformValue)
})

const headerImageAltText = computed(() => {
  return isMediumUp.value && props.headerImageDesktop
    ? props.headerImageDesktopAlt
    : props.headerImageAlt
})

const headerImageStyles = computed(() => {
  return props.mobileHeaderImageWidth && !isMediumUp.value
    ? { 'max-width': props.mobileHeaderImageWidth }
    : null
})

const checkmarkIconSize = computed(() => {
  return isMediumUp.value ? '24' : '20'
})
</script>

<style lang="scss">
.ValueProps {
  @include for-medium-up {
    padding-top: var(--spacing-12x);
    padding-bottom: var(--spacing-12x);
  }

  &_Wrapper {
    background-color: var(--surface-color-linen);
    padding: var(--spacing-8x) var(--spacing-4x);

    @include for-medium-up {
      padding-top: var(--spacing-12x);
      padding-bottom: var(--spacing-12x);
    }

    &-Theme-dark {
      background-color: var(--surface-color-dark);
      color: var(--text-color-primary-ondark);

      .SkyLink--inline {
        --text-color-action-primary: var(--text-color-primary-ondark);
        --text-color-action-primary-hover: var(
          --text-color-action-hover-ondark
        );
        border-bottom-color: var(--text-color-primary-ondark);
      }

      .ValueProps_Header {
        color: var(--text-color-primary-ondark);
      }

      .ValueProps_Subhead {
        color: var(--text-color-primary-ondark);
      }

      .ValueProps_List_Item_Content_Subhead {
        color: var(--text-color-standards-ondark);
      }
    }

    &-Theme-transparent,
    &-Theme-VIP {
      background-color: var(--surface-color-default);

      @include for-medium-up {
        padding: 0;
      }
    }

    &-Theme-VIP {
      --text-color-action-primary: var(--text-color-vip-primary-ondark);
      --text-color-action-primary-hover: var(--text-color-vip-primary-ondark);
    }
  }

  &_Container {
    display: grid;

    @include for-medium-up {
      max-width: var(--container-max);
      margin: 0 auto;
    }
  }

  &_Disclaimer {
    @include type-body('xs');
    text-align: center;
    margin: var(--spacing-6x) 0 0;
  }

  &_Header {
    @include type-brand-display('2');
    margin-bottom: var(--spacing-2x);

    @include for-medium-up {
      text-align: center;
      margin-bottom: var(--spacing-4x);
    }
  }

  &_Subhead {
    @include type-body(m);
    margin-bottom: var(--spacing-2x);
    line-height: var(--line-height-tight);

    @include for-medium-up {
      text-align: center;
      margin-bottom: var(--spacing-8x);
      font-size: var(--font-size-200);
    }
  }

  &_Cta {
    margin-top: var(--spacing-4x);
    text-align: center;

    .SkyLink {
      font-size: var(--font-size-100);
    }

    .SkyLink--block {
      text-align: center;
    }
  }

  &_HeaderImage {
    margin-bottom: var(--spacing-6x);

    @include for-medium-up {
      margin: 0 auto var(--spacing-6x);
      max-width: 200px;
    }
  }

  &_List {
    list-style-type: none;
    margin: 0;
    padding: 0;

    @include for-medium-up {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(145px, 1fr));
      grid-gap: var(--spacing-8x);
      justify-content: center;
    }

    &_Item {
      font-family: var(--font-family-default-medium);
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-100);

      @include for-medium-up {
        font-size: var(--font-size-200);
        margin-bottom: var(--spacing-2x);
      }

      &_Content {
        display: grid;
        grid-template-columns: 24px 1fr;
        grid-gap: var(--spacing-1x);
        margin-bottom: var(--spacing-2x);
        line-height: var(--line-height-tight);

        &_WithImage {
          grid-template-columns: var(--spacing-16x) 4fr;
        }

        @include for-medium-up {
          grid-gap: var(--spacing-4x);
          grid-template-columns: inherit;
          justify-items: center;
          text-align: center;
        }

        &_Subhead {
          margin-top: var(--spacing-halfx);
          @include type-body(s);

          @include for-medium-up {
            font-size: var(--font-size-100);
          }
        }
      }

      &_Image {
        width: var(--spacing-16x);

        @include for-medium-up {
          width: var(--spacing-24x);
        }
      }
    }
  }
}
</style>
