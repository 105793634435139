<script lang="ts" setup>
import type { MarkdownParsedContent } from '@nuxt/content/dist/runtime/types'
import { type ContentfulAsset } from '~/types/contentful'

type SkyImageInstance = InstanceType<typeof SkyImage>
type SrcSets = SkyImageInstance['srcsets']

const baseTransforms = 'f_auto,fl_progressive,q_auto:low,ar_4:3,c_pad'

const markdownComponents = {
  a: 'ContentProseA',
  ul: 'ContentProseUl',
  li: 'ContentProseLi',
  ol: 'ContentProseOl',
  h3: 'ContentProseH3',
  img: 'ContentProseImg',
}

const props = defineProps({
  subhead: {
    type: String,
    default: null,
  },
  copy: {
    type: Object as PropType<MarkdownParsedContent>,
    default: null,
  },
  image: {
    type: Object as PropType<ContentfulAsset>,
    default: null,
  },
  imageAlt: {
    type: String,
    default: '',
  },
  buttonEnabled: {
    type: Boolean,
    default: false,
  },
  buttonLabel: {
    type: String,
    default: null,
  },
  buttonLink: {
    type: String,
    default: null,
  },
})

const imageDescription = computed<string>(
  () => props.imageAlt || props.image?.description || ''
)

const imageSrcSets = computed<SrcSets>(() => {
  const imagePath = props.image?.url || ''
  return imagePath
    ? [620, 1240].map((width) => ({
        width,
        src: transformImage(imagePath, `${baseTransforms},w_${width}`),
      }))
    : []
})
</script>

<template>
  <article class="ShowcaseArticle">
    <SkyImage
      v-if="image"
      class="ShowcaseArticle_Image"
      :src="transformImage(image.url || '', `${baseTransforms},w_620`)"
      :alt="imageDescription"
      :srcsets="imageSrcSets"
    />
    <h3 v-if="subhead" class="ShowcaseArticle_Heading">{{ subhead }}</h3>
    <ContentRendererMarkdown
      v-if="copy"
      class="ShowcaseArticle_Copy"
      :components="markdownComponents"
      :value="copy"
    />
    <SkyLink
      v-if="buttonEnabled && buttonLink && buttonLabel"
      button
      display
      :to="buttonLink"
    >
      {{ buttonLabel }}
    </SkyLink>
  </article>
</template>

<style lang="scss">
.ShowcaseArticle {
  display: flex;
  flex-direction: column;

  &_Heading {
    @include type-heading('3');
    margin-bottom: var(--spacing-2x);

    @include for-medium-up {
      margin-bottom: 0;
    }
  }

  &_Image {
    margin-bottom: var(--spacing-8x);
  }

  &_Copy {
    margin-bottom: auto;
  }
}
</style>
