<script setup lang="ts">
import { SkyLink } from '@groveco/skylight'
import { computed } from 'vue'
import type { ContentfulAsset } from '~/types/contentful'

const props = defineProps({
  headline: {
    type: String,
    required: true,
  },
  ctaText: {
    type: String,
    default: '',
  },
  ctaUrl: {
    type: String,
    default: '',
  },
  mobileImage: {
    type: Object as PropType<ContentfulAsset>,
    required: true,
  },
  mobileAltText: {
    type: String,
    default: '',
  },
  desktopImage: {
    type: Object as PropType<ContentfulAsset>,
    required: true,
  },
  desktopAltText: {
    type: String,
    default: '',
  },
  backgroundColor: {
    type: String,
    default: '',
  },
  titleCtaColor: {
    type: String,
    default: '',
  },
  subcopy: {
    type: String,
    default: '',
  },
  logoImage: {
    type: Object as PropType<ContentfulAsset>,
    default: null,
  },
  logoAltText: {
    type: String,
    default: '',
  },
  logoImageWidth: {
    type: [String, Number],
    default: '100%',
  },
  desktopColumnAlignment: {
    type: String as PropType<'left' | 'right' | 'center'>,
    default: '',
  },
  mobileHorizontalTextAlignment: {
    type: String as PropType<'left' | 'center'>,
    default: '',
  },
  mobileVerticalContentAlignment: {
    type: String as PropType<'top' | 'center' | 'bottom'>,
    default: '',
  },
  darkCtaBackground: {
    type: Boolean,
    default: false,
  },
  lightTextContent: {
    type: Boolean,
    default: false,
  },
})

const { isMediumUp, isReady, isSmallUp } = useSkyBreakpoint()

const mobileImageSrc = computed(() => {
  const mobileTransforms = 'fl_progressive,q_auto,g_west,c_fill,h_500'
  return transformImage(props.mobileImage?.url || '', mobileTransforms)
})

const desktopImageSrc = computed(() => {
  const desktopTransforms = 'fl_progressive,f_auto,g_west,q_auto,c_fill,h_1000'
  return transformImage(props.desktopImage?.url || '', desktopTransforms)
})

const logoImageSrc = computed<string | undefined>(() => {
  return props.logoImage?.url
})

const imageSrc = computed(() => {
  return isMediumUp.value ? desktopImageSrc.value : mobileImageSrc.value
})

const imageAltText = computed(() => {
  return isMediumUp.value ? props.desktopAltText : props.mobileAltText
})

const imageClasses = computed(() => {
  const baseClass = 'ImageCtaHero_Image'
  return {
    [baseClass]: true,
    [`${baseClass}-ready`]: isReady.value,
    [`${baseClass}-desktop`]: isMediumUp.value,
  }
})

const hasBackgroundColor = computed<boolean>(() => {
  return Boolean(props.backgroundColor)
})

const componentClasses = computed(() => {
  const baseClass = 'ImageCtaHero'
  return {
    [baseClass]: true,
    [`${baseClass}--color-background`]: hasBackgroundColor.value,
    [`${baseClass}--image-background`]: !hasBackgroundColor.value,
  }
})

const componentStyles = computed(() => {
  const styles: Record<string, string> = {}
  if (hasBackgroundColor.value) {
    styles['--bg-color'] = props.backgroundColor
    styles['--text-color'] = props.titleCtaColor
  } else {
    styles['background-image'] = `url('${imageSrc.value}')`
  }

  return styles
})

const componentType = computed(() => (props.ctaUrl ? SkyLink : 'div'))

const componentProps = computed(() =>
  props.ctaUrl
    ? { to: props.ctaUrl, unstyled: true }
    : { 'data-test-id': 'div-component' }
)

const wrapperClasses = computed(() => {
  const baseClass = 'ImageCtaHero_Wrapper'
  return {
    [baseClass]: true,
    [`${baseClass}--color-background`]: hasBackgroundColor.value,
    [`${baseClass}--image-background`]: !hasBackgroundColor.value,
    [`${baseClass}--desktop-column-align-${props.desktopColumnAlignment.toLowerCase()}`]:
      Boolean(props.desktopColumnAlignment) && !hasBackgroundColor.value,
  }
})

const contentClasses = computed(() => {
  const baseClass = 'ImageCtaHero_Content'
  return {
    [baseClass]: true,
    [`${baseClass}--image-background`]: !hasBackgroundColor.value,
    [`${baseClass}--light-text-content`]:
      !hasBackgroundColor.value && props.lightTextContent,
    [`${baseClass}--mobile-vertical-content-align-${props.mobileVerticalContentAlignment.toLowerCase()}`]:
      !hasBackgroundColor.value,
    [`${baseClass}--mobile-horizontal-text-align-${props.mobileHorizontalTextAlignment.toLowerCase()}`]:
      !hasBackgroundColor.value,
  }
})

const ctaClasses = computed(() => {
  const baseClass = 'ImageCtaHero_Cta'
  return {
    [baseClass]: true,
    [`${baseClass}--custom`]: hasBackgroundColor.value,
  }
})

const isCtaDark = computed<boolean>(
  () =>
    !hasBackgroundColor.value &&
    props.darkCtaBackground &&
    !props.lightTextContent
)
</script>

<template>
  <component
    :is="componentType"
    v-bind="componentProps"
    :class="componentClasses"
    :style="componentStyles"
  >
    <SkyImage
      v-if="hasBackgroundColor"
      :class="imageClasses"
      data-test-id="image"
      :src="imageSrc"
      :alt="imageAltText"
    />
    <div :class="wrapperClasses">
      <div :class="contentClasses">
        <div v-if="logoImageSrc" class="ImageCtaHero_LogoContainer">
          <SkyImage
            class="ImageCtaHero_Logo"
            data-test-id="logo"
            :alt="logoAltText"
            :src="logoImageSrc"
            :style="{ width: `${logoImageWidth}` }"
          />
        </div>
        <h2 class="ImageCtaHero_Headline" data-test-id="headline">
          {{ headline }}
        </h2>
        <p v-if="subcopy" data-test-id="subcopy" class="ImageCtaHero_Subcopy">
          {{ subcopy }}
        </p>
        <SkyButton
          v-if="ctaText && ctaUrl"
          :class="ctaClasses"
          :outlined="hasBackgroundColor"
          :dark="isCtaDark"
          :block="!isSmallUp && mobileHorizontalTextAlignment == 'center'"
          data-test-id="cta"
        >
          {{ ctaText }}
        </SkyButton>
      </div>
    </div>
  </component>
</template>

<style lang="scss">
.ImageCtaHero {
  &--color-background {
    display: grid;
    grid-template-columns: max-content;
    grid-template-rows: minmax(0, 1fr) minmax(0, auto);
    grid-template-areas: 'image wrapper';
    border: none;
    background-color: var(--bg-color);

    @include for-medium-up {
      min-height: 500px;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: 'image-desktop wrapper';
    }
  }

  &--image-background {
    display: block;
    position: relative;
    background-size: cover;
    background-position: 15% center;
    background-repeat: no-repeat;

    @include for-medium-up {
      background-position: top center;
    }
  }

  &_Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    &--color-background {
      grid-area: wrapper;
      grid-row: 2;
      margin: var(--spacing-4x) var(--spacing-4x) var(--spacing-8x);

      @include for-medium-up {
        grid-row: unset;
        margin: var(--spacing-8x);
      }
    }

    &--image-background {
      max-width: $container-large;
      min-height: 500px;
      margin: 0 auto;

      @include for-medium-up {
        padding: var(--spacing-12x) var(--spacing-4x);
      }
    }

    &--desktop-column-align-center {
      max-width: unset;
      @include for-medium-up {
        justify-content: center;
        .SkyImageCTAHero_Content {
          padding: var(--spacing-6x) 0;
        }
      }
    }

    &--desktop-column-align-left {
      @include for-medium-up {
        justify-content: left;
        .SkyImageCTAHero_Content {
          padding: var(--spacing-6x) 0 var(--spacing-6x) var(--spacing-6x);
        }
      }
    }

    &--desktop-column-align-right {
      @include for-medium-up {
        justify-content: right;
        .SkyImageCTAHero_Content {
          padding: var(--spacing-6x) var(--spacing-6x) var(--spacing-6x) 0;
        }
      }
    }
  }

  &_Content {
    text-align: center;

    @include for-medium-up {
      text-align: left;
    }

    &--image-background {
      display: flex;
      flex-direction: column;
      padding: var(--spacing-6x);
      position: absolute;
      bottom: 0;
      text-align: left;

      @include for-medium-up {
        flex-basis: 50%;
        width: 50%;
        position: unset;
        bottom: unset;
      }
    }

    &--light-text-content {
      color: var(--color-white);

      .ImageCtaHero_Cta {
        background-color: var(--color-white);
        border-color: var(--color-white);
        color: var(--text-color-action-dark);

        &:hover {
          opacity: 0.75;
        }
      }
    }

    &--mobile-vertical-content-align-top {
      @include for-small-down {
        transform: translateY(-100%);
      }
    }

    &--mobile-vertical-content-align-center {
      @include for-small-down {
        position: unset;
      }
    }

    &--mobile-horizontal-text-align-center {
      @include for-small-down {
        text-align: center;

        .ImageCtaHero_Cta {
          margin: 0 auto;
        }
      }
    }

    &--mobile-horizontal-text-align-left {
      @include for-small-down {
        text-align: left;
      }
    }
  }

  &_LogoContainer {
    width: 9.375rem;
    margin-bottom: var(--spacing-2x);

    @include for-medium-up {
      width: 12.5rem;
      margin-bottom: var(--spacing-4x);
    }
  }

  &_Headline {
    @include type-brand-display('1');
    color: var(--text-color);
    max-width: 450px;

    @include for-medium-up {
      text-align: left;
    }
  }

  &_Subcopy {
    @include type-body('m');
    color: var(--text-color);
    line-height: var(--line-height-tight);
    margin: var(--spacing-2x) 0 var(--spacing-4x);

    @include for-medium-up {
      margin: var(--spacing-4x) 0;
    }
  }

  &_Image {
    grid-area: image;
    grid-column: 2;
    grid-row: 1;
    width: 100%;
    object-fit: cover;
    object-position: bottom;
    display: block;

    @include for-medium-up {
      display: none;
      &-ready {
        display: block;
      }
      height: 100%;
    }

    &-desktop {
      grid-area: image-desktop;
      object-position: 100%;
    }
  }

  &_Cta {
    margin-top: var(--spacing-4x);
    max-width: 280px;

    &--custom {
      --text-color-action-primary: var(--text-color);
      --text-color-action-primary-hover: var(--text-color);
      --surface-color-default: var(--bg-color);
    }
  }
}
</style>
