<template>
  <div :id="id" class="WidgetShowcase1Up">
    <h2 v-if="headline" class="WidgetShowcase1Up_Headline">
      {{ headline }}
    </h2>
    <div :class="contentClasses">
      <SkyImage
        v-if="isMediaImage"
        class="WidgetShowcase1Up_Media"
        :alt="mediaAlt"
        :src="transformImage(image.url || '', `${baseTransforms},w_672`)"
        :srcsets="imageSrcSets"
      />
      <VimeoPlayer
        v-if="isMediaVimeo"
        class="WidgetShowcase1Up_Media"
        :title="mediaAlt"
        :video-id="vimeoVideoId"
      />
      <div v-if="hasDetails" :class="detailsClasses">
        <p v-if="eyebrow.trim()" class="WidgetShowcase1Up_Eyebrow">
          {{ eyebrow }}
        </p>
        <h3 v-if="subhead.trim()" :class="subheadClasses">
          {{ subhead }}
        </h3>
        <ContentRendererMarkdown
          v-if="copy"
          :components="markdownComponents"
          :value="copy"
        />
        <div
          v-if="isButtonEnabled && buttonLink && buttonLabel"
          class="WidgetShowcase1Up_CTAContainer"
        >
          <SkyLink dark display :button="ctaType" :to="buttonLink">{{
            buttonLabel
          }}</SkyLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { MarkdownParsedContent } from '@nuxt/content/dist/runtime/types'
import type { ContentfulAsset } from '~/types/contentful'

type SkyImageInstance = InstanceType<typeof SkyImage>
type SrcSets = SkyImageInstance['srcsets']

defineOptions({
  inheritAttrs: false,
})

const markdownComponents = {
  a: 'ContentProseA',
  h3: 'ContentProseH3',
  iframe: 'ContentProseIframe',
  img: 'ContentProseImg',
  p: 'ContentProseP',
}

const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  alignText: {
    type: String as PropType<'left' | 'center' | 'right'>,
    default: 'left',
  },
  buttonLabel: {
    type: String,
    default: '',
  },
  buttonLink: {
    type: String,
    default: '',
  },
  copy: {
    type: Object as PropType<MarkdownParsedContent>,
    default: null,
  },
  ctaType: {
    type: Boolean,
    default: false,
  },
  eyebrow: {
    type: String,
    default: '',
  },
  headline: {
    type: String,
    default: '',
  },
  image: {
    type: Object as PropType<ContentfulAsset>,
    default: null,
  },
  imageAlt: {
    type: String,
    default: '',
  },
  isButtonEnabled: {
    type: Boolean,
    default: false,
  },
  isImageRight: {
    type: Boolean,
    default: false,
  },
  mediaSource: {
    type: String,
    default: 'image',
  },
  regularOrLargeSubhead: {
    type: Boolean,
    default: true,
  },
  sansOrSerifSubhead: {
    type: Boolean,
    default: true,
  },
  subhead: {
    type: String,
    default: '',
  },
  smallAlignTextCenter: {
    type: Boolean,
    default: false,
  },
  textBackground: {
    type: String as PropType<'None' | 'Pattern' | 'Frost' | 'Cream'>,
    default: 'None',
  },
  vimeoVideoId: {
    type: String,
    default: '',
  },
})

const baseTransforms = 'f_auto,q_auto:low,fl_progressive,ar_4:3,c_fill,g_auto'

const { isMediumUp } = useSkyBreakpoint()

const isMediaImage = computed<boolean>(() =>
  Boolean(props.image && props.mediaSource.toLowerCase() === 'image')
)

const isMediaVimeo = computed<boolean>(() =>
  Boolean(props.vimeoVideoId && props.mediaSource.toLowerCase() === 'vimeo')
)

const hasMedia = computed<boolean>(
  () => isMediaImage.value || isMediaVimeo.value
)

const mediaAlt = computed<string>(
  () => props.imageAlt || props.image?.description || ''
)

const hasDetails = computed<boolean>(
  () =>
    Boolean(props.copy || props.subhead.trim() || props.eyebrow.trim()) ||
    props.isButtonEnabled
)

const imageSrcSets = computed<SrcSets>(() => {
  if (!isMediaImage.value) {
    return []
  }

  return [672, 1344].map((width) => ({
    width,
    src: transformImage(props.image?.url || '', `${baseTransforms},w_${width}`),
  }))
})

const textBackground = computed<string>(() => {
  return props.textBackground.toLowerCase()
})

const contentClasses = computed<string[]>(() => {
  const baseClass = 'WidgetShowcase1Up_Content'
  const classes: string[] = [baseClass]

  if (isMediumUp.value && hasMedia.value && hasDetails.value) {
    classes.push(`${baseClass}--columns`)

    if (props.isImageRight) {
      classes.push(`${baseClass}--reversed`)
    }
  }

  if (textBackground.value !== 'none') {
    classes.push(`${baseClass}--${textBackground.value}`)
  }

  return classes
})

const detailsClasses = computed<string[]>(() => {
  const baseClass = `WidgetShowcase1Up_Details`
  const classes: string[] = [baseClass]

  if (hasMedia.value) {
    // when media is present, pad the text details container a bit
    classes.push(`${baseClass}--padded`)
  } else if (textBackground.value !== 'none') {
    // if no media is present, but we have specified a background give a
    // large amount of padding to the text details container
    classes.push(`${baseClass}--padded-large`)
  }

  let alignText = props.alignText

  // Force left alignment if image is on the right
  // or text is centered on a small viewport.
  if (
    (hasMedia.value && props.isImageRight) ||
    (props.alignText === 'center' && !isMediumUp.value)
  ) {
    alignText = 'left'
  }

  // Force center alignment if text is centered on a small viewport.
  if (props.smallAlignTextCenter && !isMediumUp.value) {
    alignText = 'center'
  }

  classes.push(`${baseClass}--${alignText}`)

  return classes
})

const subheadClasses = computed<string[]>(() => {
  const baseClass = 'WidgetShowcase1Up_Subhead'
  const classes: string[] = [baseClass]

  if (!props.regularOrLargeSubhead && isMediumUp.value) {
    classes.push(`${baseClass}--large`)
  }

  if (!props.sansOrSerifSubhead) {
    classes.push(`${baseClass}--sans`)
  }

  return classes
})
</script>

<style lang="scss">
.WidgetShowcase1Up {
  padding: var(--spacing-10x) 0;

  &_Headline {
    @include type-brand-heading('2');
    text-align: center;
    margin: var(--spacing-6x) 0;

    @include for-medium-up {
      @include type-brand-display('2');
      margin: var(--spacing-8x) 0;
    }
  }

  &_Eyebrow {
    --text-color-primary: var(--text-color-action-secondary);
    margin: 0 0 var(--spacing-2x);
    @include type-section('m');
  }

  &_Subhead {
    margin-top: 0;
    margin-bottom: var(--spacing-4x);

    &--large {
      font-size: var(--font-size-500);
    }

    &--sans {
      font-family: var(--font-family-display);
    }
  }

  &_Content {
    &--columns {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: 'media details';
      align-items: center;
    }

    &--reversed {
      grid-template-areas: 'details media';
    }

    &--frost {
      background-color: var(--surface-color-frost);
    }

    &--cream {
      background-color: var(--surface-color-cream);
    }

    &--pattern {
      background-image: var(--background-pattern-url);
    }
  }

  &_Media {
    grid-area: media;
    align-self: baseline;
  }

  &_Details {
    grid-area: details;

    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;
    }

    &--center {
      text-align: center;
    }

    &--padded {
      padding: var(--spacing-4x) var(--spacing-2x);

      @include for-medium-up {
        padding: var(--spacing-6x);
      }
    }

    &--padded-large {
      padding: var(--spacing-12x);
    }
  }

  &_Copy {
    // Prevent copy from breaking out of its container
    max-width: 100%;
    word-wrap: break-word;
  }

  &_CTAContainer {
    margin-top: var(--spacing-6x);
  }
}
</style>
