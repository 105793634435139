<script setup lang="ts">
import type { MarkdownParsedContent } from '@nuxt/content/dist/runtime/types'
import { BACK_SOON_BADGE_TEXT } from '~/constants/productBadges'
import {
  FALLBACK_PRODUCT_IMAGE,
  PRODUCT_TILE_TRANSFORMATIONS,
} from '~/constants/images'

const markdownComponents = {
  a: 'ContentProseA',
  ul: 'ContentProseUl',
  h3: 'ContentProseH3',
}

/**
 * The component for ProductListicleTile widgets.
 *
 * Displays a heading, a product tile, and a product description.
 */
const props = defineProps({
  /**
   * The id of the cms widget.
   */
  id: {
    type: String,
    default: '',
  },
  /**
   * The name of the CMS widget.
   */
  name: {
    type: String,
    default: '',
  },
  /**
   * The heading to display for thw widget.
   */
  heading: {
    type: String,
    default: '',
  },
  /**
   * The variant id of the product to display.
   */
  variantId: {
    type: String,
    required: true,
  },
  /**
   * The product description.
   */
  description: {
    type: Object as PropType<MarkdownParsedContent>,
    default: null,
  },
  /**
   * A flag that reverses the layout order.
   */
  reversed: {
    type: Boolean,
    default: false,
  },
})

const { results, fetchRecommendations } = useCatalogRecommendations()
const { isSmallUp } = useSkyBreakpoint()

watch(
  () => props.variantId,
  (variantId) => {
    if (variantId != null) {
      fetchRecommendations('browse', { variantIds: [variantId] })
    }
  },
  { immediate: true }
)

const contentClasses = computed(() => {
  const baseClass = 'WidgetProductListicleTile_Content'
  return {
    [`${baseClass}`]: true,
    [`${baseClass}--reversed`]: props.reversed,
  }
})

const cardContainerClass = computed(() => {
  if (props.reversed) {
    return 'WidgetProductListicleTile_CardContainer--reversed'
  }
})
</script>

<template>
  <div
    v-for="item in results"
    :id="id"
    :key="item.variantId"
    class="WidgetProductListicleTile"
  >
    <SkyLink :tag="tag" :to="`${item.productUrl}`" unstyled>
      <h3 class="WidgetProductListicleTile_Heading">{{ heading }}</h3>
    </SkyLink>
    <div :class="contentClasses">
      <div
        :class="cardContainerClass"
        :data-cnstrc-item-id="item.id"
        :data-cnstrc-item-name="item.name"
        :data-cnstrc-item-price="item.price"
        :data-cnstrc-item-variation-id="item.variantId"
      >
        <SkyCard class="WidgetProductListicleTile_Card" rounded>
          <SkyProductTile
            :badge-inverted="item.badgeText === BACK_SOON_BADGE_TEXT"
            :badge-text="item.badgeText"
            :brand-name="item.brandName"
            hide-zero-values
            :image-alt="item.name"
            :image-placeholder="FALLBACK_PRODUCT_IMAGE"
            :image-src="
              transformImage(
                item.imageSrc,
                `${PRODUCT_TILE_TRANSFORMATIONS}${
                  item.outOfStock ? ',e_grayscale' : ''
                }`
              )
            "
            :offer-price="item.price"
            :offer-text="
              item.hasVipSpecialPricingDiscount
                ? `VIP ${item.discountText}`
                : item.discountText
            "
            :price="item.listPrice"
            :product-name="item.productName"
            :product-url="`${item.productUrl}`"
            :product-rating="item.reviewRating"
            :rating-count="item.reviewCount"
            :secondary-text="item.secondaryText"
            :variant-count="item.variants.length || undefined"
            :show-divider="!isSmallUp"
            align-all-items
          >
            <template
              v-if="item.badgeText || results[0].hasVipSpecialPricingDiscount"
              #topBadge
            >
              <SkyBadge
                v-if="item.badgeText"
                :inverted="item.badgeText === BACK_SOON_BADGE_TEXT"
              >
                {{ item.badgeText }}
              </SkyBadge>
              <SkyBadge
                v-if="item.hasVipSpecialPricingDiscount"
                class="WidgetProductListicleTile_ProductTileBadge--vip"
              >
                VIP Discount
              </SkyBadge>
            </template>
            <template v-if="item.variantId" #cta>
              <AddToCartButton
                :variant-id="item.variantId"
                :out-of-stock="item.outOfStock"
              />
            </template>
            <template
              v-if="item.reviewRating === null && results[0].sku"
              #rating
            >
              <div
                :data-bv-product-id="item.sku"
                :data-bv-redirect-url="`${item.productUrl}#reviews-section`"
                data-bv-seo="false"
                data-bv-show="inline_rating"
                data-test-id="bv-inline-star-rating"
              ></div>
            </template>
          </SkyProductTile>
        </SkyCard>
      </div>
      <SkyLink
        :tag="tag"
        :to="`${item.productUrl}`"
        class="WidgetProductListicleTile_Description"
        unstyled
      >
        <ContentRendererMarkdown
          :components="markdownComponents"
          :value="description"
        />
      </SkyLink>
    </div>
  </div>
</template>

<style lang="scss">
.WidgetProductListicleTile {
  display: grid;
  grid-gap: var(--spacing-8x);
  grid-template-rows: auto;

  @include for-medium-up {
    grid-gap: var(--spacing-4x);
  }

  &_Heading {
    @include type-brand-heading('1');
    text-align: center;
  }

  &_Content {
    display: grid;
    grid-template-rows: auto;
    grid-gap: var(--spacing-8x);

    &--reversed {
      @include for-medium-up {
        grid-auto-flow: dense;
      }
    }

    @include for-medium-up {
      grid-gap: var(--spacing-8x);
      grid-template-columns: auto 1fr;
      margin: 0 auto;
    }
  }

  &_CardContainer--reversed {
    @include for-medium-up {
      grid-column: 2;
    }
  }

  &_Card {
    max-width: 212px;

    @include for-small-down {
      margin: 0 auto;
    }

    @include for-medium-up {
      max-width: 208px;
    }
  }

  &_Description {
    @include for-medium-up {
      max-width: 568px;
    }
  }

  &_ProductTileBadge {
    &--vip {
      .SkyPrice {
        --text-color-savings: var(--text-color-vip);
      }
    }
  }
}
</style>
