<template>
  <component
    :is="widget.component"
    v-for="widget in widgets"
    :key="widget.key"
    v-bind="widget.bindings"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { ConcreteComponent } from 'vue'
import type { ContentfulEntry } from '~/types/contentful'

/**
 * Each content type in Contentful has a unique name called `contentType`.
 * This object acts as a translation mapping between those unique names and the
 * components responsible for rendering the content type's data.
 */
const componentForContentType: Record<string, ConcreteComponent | string> = {
  carouselQuote: resolveComponent('WidgetCarouselQuote'),
  productLaunchHero: resolveComponent('WidgetProductLaunchHero'),
  shoppingMedium: resolveComponent('WidgetShoppingMedium'),
  showcase1up: resolveComponent('WidgetShowcase1Up'),
  tableOfContents: resolveComponent('TableOfContents'),
  showcase2AndUp: resolveComponent('WidgetShowcase2AndUp'),
  imageCtaHero: resolveComponent('WidgetImageCtaHero'),
  xGrid: resolveComponent('WidgetImageCardGrid'),
  claimAnOffer: resolveComponent('WidgetClaimAnOffer'),
  basicProductCarousel: resolveComponent('WidgetProductCarousel'),
  basicValuePropsWithHeaderImage: resolveComponent('WidgetValueProps'),
  bannerSmallHeadline: resolveComponent('WidgetBannerSmallHeadline'),
  gridImage: resolveComponent('WidgetGridImage'),
  cardCarousel: resolveComponent('WidgetCardCarousel'),
  productListicleTile: resolveComponent('WidgetProductListicleTile'),
}

interface Entry extends ContentfulEntry {
  [key: string]: any
}

const props = defineProps({
  entries: {
    type: Array<Entry>,
    required: true,
  },
})

const config = useRuntimeConfig()
const debug = config.public.env === 'development'

const fallback = (contentTypeId: string, name: string) =>
  defineComponent({
    name: 'WidgetNotFound',
    props: {},
    render: debug
      ? () =>
          h(
            'p',
            {},
            `[[ DEBUG: Not rendered: ${contentTypeId} '${name}' (see Vue component tree for data) ]]`
          )
      : () => null,
  })

const widgets = computed(() =>
  props.entries.map(({ contentTypeId, ...bindings }) => {
    const key = `entry-${bindings.id}`
    const component: string | ConcreteComponent =
      componentForContentType[contentTypeId] ??
      fallback(contentTypeId, bindings.name)

    if (!(bindings.id && contentTypeId in componentForContentType)) {
      // eslint-disable-next-line no-console
      console.warn(
        `[ContentfulContent] Could not resolve \`${contentTypeId}\` content type.`
      )
    }
    return {
      bindings,
      component,
      key,
    }
  })
)
</script>
