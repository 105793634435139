<script setup lang="ts">
import type { ContentfulAsset } from '~/types/contentful'
import { type ImageCard } from '~/types/image-card'

const { isMediumUp, isLargeUp } = useSkyBreakpoint()

const baseTransforms =
  'f_auto,fl_progressive,h_368,w_auto,q_auto,dpr_auto,ar_1:1,c_pad,b_white'

const props = defineProps({
  heading: {
    type: String,
    default: '',
  },
  imageCards: {
    type: Array as PropType<Array<ImageCard>>,
    default: () => [],
  },
})

const numItemsToDisplay = computed<number>(() => {
  if (isLargeUp.value) {
    return 6
  } else if (isMediumUp.value) {
    return 4
  } else {
    return 3.5
  }
})

const showNav = computed<boolean>(() => {
  return isMediumUp.value
    ? numItemsToDisplay.value < props.imageCards.length
    : false
})

const getImageProps = (image: ContentfulAsset): Record<string, string> => {
  const imagePath = image?.url || ''

  return {
    src: transformImage(imagePath, baseTransforms),
    alt: image?.description || '',
    loading: 'eager',
  }
}
</script>

<template>
  <div class="CardCarousel">
    <h3 v-if="heading" class="CardCarousel_Heading" data-test-id="heading">
      {{ heading }}
    </h3>
    <SkyCarousel
      v-if="imageCards.length"
      class="CardCarousel_Carousel"
      :navigation="showNav"
      :slides-to-show="numItemsToDisplay"
      :slides-to-scroll="Math.round(numItemsToDisplay - 1)"
    >
      <SkyLink
        v-for="(card, index) in imageCards"
        :key="index"
        :to="card.cardDestinationUrl"
        class="CardCarousel_Link"
        unstyled
      >
        <div class="CardCarousel_ImageContainer">
          <SkyImage
            v-bind="getImageProps(card.image)"
            class="CardCarousel_Image"
          />
          <div class="CardCarousel_Text">{{ card.cardText }}</div>
        </div>
      </SkyLink>
    </SkyCarousel>
  </div>
</template>

<style lang="scss">
.CardCarousel {
  display: grid;
  margin: var(--spacing-8x) 0;

  &_Heading {
    margin: var(--spacing-4x) 0 var(--spacing-2x) 0;
    @include type-brand-heading('2');
    text-align: center;

    @include for-medium-up {
      @include type-brand-heading('1');
    }
  }

  &_Link {
    margin: 0 calc(var(--spacing-2x) * -1) 0 var(--spacing-4x);
    padding: var(--spacing-3x);
    overflow: hidden;
    height: 100%;
  }

  &_ImageContainer {
    display: grid;
    align-items: center;
    text-align: center;
    height: auto;
    width: 100%;
    margin-right: 0;
  }

  &_Image {
    width: 100px;
    border-radius: var(--border-radius-default);

    @include for-medium-up {
      width: 150px;
    }
  }

  &_Text {
    font-size: var(--font-size-75);
    line-height: var(--line-height-tight);
    margin-top: var(--spacing-2x);
  }
}
</style>
